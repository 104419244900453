import Header from './Header'
import Footer from './Footer'
import Logo from './Logo'

function App() {
  return (
    <div>
      <main>
          <Header />
          <Logo />
          <Footer />
      </main>
    </div>
  );
}

export default App;

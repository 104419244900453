function Footer() {
    return (
        <div className='Footer'>
        <ul className='left'>
            <li>
                <h3>Michael May</h3>
            </li>
            <li>
                <p>Owner & Project Manager</p>
            </li>
        </ul>
        <ul className='right'>
            <li>
                <h3>207-217-4071</h3>
            </li>
            <li>
                <p>Call or Text Message</p>
            </li>
        </ul>
    </div>
    );
}

export default Footer;